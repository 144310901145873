.list_title_box {
  padding: 1.142857rem 0 0.285714rem;
  width: 100%;
  height: 2rem;
}

.list_title_box_unfold {
  height: auto;
}

.list_title_box_unfold h3 {
  white-space: normal !important;
  text-overflow: clip !important;
  text-align: justify !important;
}

.list_title_box_unfold h3 a {
  vertical-align: top;
}

.list_title {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
}

.list_title a {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #212223;
}

.list_title a:hover {
  color: #f54343;
  text-decoration: none;
}

.list_title_box_unfold .list_title {
  height: auto;
  text-align: justify !important;
}

.big a {
  font-size: 1.428571rem;
}

.normal a {
  font-size: 1.142857rem;
}

.space {
  margin-right: 1.428571rem;
}