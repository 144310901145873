.nav {
  width: 100%;
  background: #f54343;
}

.bgnone {
  width: 100%;
  background: none;
}

.ssr_nav {
  margin: 0 auto;
  width: 100%;
  height: 2.714286rem;
}