.left_box {
  float: left;
  margin-right: 2.785714rem;
  width: 34.285714rem;
}

.dbox {
  margin-top: 1.428571rem;
  width: 34.285714rem;
  height: 13rem;
}