.news_box {
  padding: 0.785714rem 0;
  width: 34.285714rem;
  height: 10.857143rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.news {
  float: left;
  position: relative;
  display: block;
  width: 16.428571rem;
  height: 9.285714rem;
}

.news:first-child {
  margin-right: 1.428571rem;
}

.news img {
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.571429rem 0.714286rem 0.142857rem;
  width: 15rem;
  height: 1.428571rem;
  background: #000000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .5)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
  filter: alpha(opacity=50);
  overflow: hidden;
  cursor: pointer;
}

.text span {
  height: 1.428571rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.text:hover span {
  text-decoration: underline;
}