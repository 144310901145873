.news_box {
  composes: clearfix from global;
  margin: 0.857143rem auto 0;
  width: 100rem;
}

.box_r {
  float: left;
  width: 62.857143rem;
}

.d_box {
  margin-top: 1.428571rem;
  width: 62.857143rem;
  height: 7.571429rem;
}