.news_box {
  composes: clearfix from global;
  margin: 0 auto;
  width: 100rem;
}

.box_l {
  float: left;
  width: 74.285714rem;
}

.box_r {
  float: left;
  width: 25.714286rem;
}