.flive_box {
  position: relative;
  width: 100%;
}

.flive_box [ifeng_ui_pc_sliders-dots] {
  position: absolute;
  top: 0.571429rem;
  right: 0;
  height: 0.285714rem;
  line-height: 0.285714rem;
}

.slide_box {
  position: relative;
  width: 100%;
}

.main_box {
  position: relative;
  margin-top: 1.428571rem;
  width: 100%;
  height: 14.5rem;
}

.main_box a {
  display: block;
  width: 100%;
  height: 100%;
}

.main_box a img {
  width: 100%;
  height: 100%;
}

.main_box a > span {
  display: block;
  position: absolute;
  top: 0.714286rem;
  left: 0.714286rem;
  width: 3.285714rem;
  height: 1.214286rem;
  line-height: 1.214286rem;
  border-radius: 0.142857rem;
  font-size: 0.714286rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.nolive {
  background: #999999;
}

.live {
  background: #f54343;
}

.title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3.428571rem;
  background: #000000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  filter: alpha(opacity=70);
}

.title a {
  position: absolute;
  top: 1.285714rem;
  left: 0.714286rem;
  display: block;
  width: 24.285714rem;
  height: 1.571429rem;
  line-height: 1.571429rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
}

.sub_box {
  composes: clearfix from global;
  margin-top: 0.714286rem;
  width: 25.714286rem;
  height: 8.428571rem;
}

.sub {
  position: relative;
  width: 12.5rem;
  height: 7rem;
}

.sub a {
  width: 100%;
  height: 100%;
}

.sub a img {
  width: 100%;
  height: 100%;
}

.sub_l {
  float: left;
}

.sub_r {
  float: right;
}

.sub_title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.142857rem;
  background: #000000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  filter: alpha(opacity=70);
}

.sub_title a {
  position: absolute;
  top: 0.571429rem;
  left: 0.714286rem;
  display: block;
  width: 11.785714rem;
  height: 1.428571rem;
  line-height: 1.428571rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  overflow: hidden;
}

.dot {
  margin-left: 4px;
  margin-right: 0;
  width: 4px;
  height: 4px;
  background: #9e9e9e;
  border-radius: 2px;
  opacity: 0.5;
}

.dot:first-child {
  margin-left: 0;
}

.current {
  composes: dot;
  width: 12px;
}