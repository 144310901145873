.phtvPeopleBox {
  width: 100%;
  height: 2.714286rem;
  line-height: 2.714286rem;
  overflow: hidden;
}

.phtvPeopleLink {
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223 !important;
  line-height: 1.571429rem;
  margin-right: 1.142857rem;
}

.phtvPeopleLastLink {
  margin-right: 0;
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223 !important;
  line-height: 1.571429rem;
}

.phtvPeopleLastLink:hover,
.phtvPeopleLink:hover {
  color: #f54343 !important;
  text-decoration: none;
}