.center_box {
  float: left;
  margin-right: 2.785714rem;
  width: 34.285714rem;
}

.adBox {
  height: 2.714286rem;
  overflow: hidden;
  display: flew;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}