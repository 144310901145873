.lowBrower {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.7;
  filter: alpha(opacity=70);
  z-index: 9999999999;
}

.close {
  position: absolute;
  top: 1.428571rem;
  right: 1.428571rem;
  font-size: 1rem;
  color: red;
  cursor: pointer;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.col_box {
  position: fixed;
  top: 18%;
  left: 0;
  right: 0;
  z-index: 9999999999;
}

.col {
  position: relative;
  width: 100rem;
  margin: 0 auto;
  padding-top: 1.428571rem;
  text-align: left;
  background: #ffffff;
}

.tip_content {
  height: 24.285714rem;
}

.ifengLogo {
  display: inline-block;
  padding-left: 1.428571rem;
}

.col .tips {
  display: inline-block;
  width: 60%;
}

.col .tips {
  position: absolute;
  left: 1.428571rem;
}

.ifengLogo span {
  display: inline-block;
  width: 9.928571rem;
  height: 3.714286rem;
  background: url(//p1.ifengimg.com/a/2018/0920/logo.jpg) no-repeat;
}

.col .downApp {
  display: inline-block;
  width: 36%;
  position: absolute;
  right: 1.428571rem;
}

.downApp img {
  display: inline-block;
  width: 25.714286rem;
  height: 23rem;
}

.col .tips h2 {
  margin-top: 2.857143rem;
  font-size: 1.428571rem;
}

.col .tips span {
  line-height: 4.285714rem;
  height: 4.285714rem;
  font-size: 1rem;
}

.col .tips .otherBrowser_logo {
  display: inline-block;
  width: 1.214286rem;
  height: 1.214286rem;
  margin-right: 0.357143rem;
}

.col .tips a {
  color: blue;
  text-decoration: underline;
  margin-left: 0.357143rem;
}

.col .ad {
  width: 100rem;
  height: 6.428571rem;
  text-align: center;
  line-height: 6.428571rem;
  background: gray;
}