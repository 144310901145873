.ipad_layout {
  position: relative;
  margin: 0 1.428571rem;
}

.layout {
  position: relative;
}

.topBox {
  composes: clearfix from global;
  position: relative;
  margin: 0 auto;
  width: 100rem;
  background: #ffffff;
}

.topDbox0 {
  margin: 0 auto;
  width: 100rem;
  min-height: 9rem;
}

.topDbox1 {
  margin: 0 auto;
  width: 100rem;
  height: 4.571429rem;
}

.bottomDbox {
  margin: 2.142857rem auto 0;
  width: 100rem;
  height: 9rem;
}

:global input[type='button'],
:global input[type='submit'],
:global input[type='reset'] {
  -webkit-appearance: none;
}

:global textarea {
  -webkit-appearance: none;
}

:global button {
  border-radius: 0;
}

:global .shopNoTagRegion .hideMark {
  display: none;
}