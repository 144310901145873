.jubao {
  width: 100%;
}

.wljd {
  display: block;
  width: 8.571429rem;
  height: 1.428571rem;
  overflow: hidden;
  z-index: 1000;
}

.wljd img {
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
}