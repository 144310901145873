.slide_box {
  width: 25.714286rem;
  height: 14.5rem;
  margin: 1.428571rem 0 1.071429rem;
}

.slide_box [ifeng_ui_pc_sliders-dots] {
  position: absolute;
  right: 0;
  top: -0.857143rem;
  height: 0.285714rem;
  line-height: 0.285714rem;
  text-align: right;
}

.slide_box [ifeng_ui_pc_sliders-arrows_pre] {
  display: none;
}

.slide_box [ifeng_ui_pc_sliders-arrows_next] {
  display: none;
}

.big_pic {
  position: relative;
  width: 25.714286rem;
  height: 14.5rem;
}

.pic_img {
  display: block;
  width: 25.714286rem;
  height: 14.5rem;
}

.pic_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 25.714286rem;
  height: 3.428571rem;
  background: #000000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  filter: alpha(opacity=70);
}

.title {
  position: absolute;
  left: 0.714286rem;
  bottom: 1.142857rem;
  width: 24.285714rem;
  height: 1.571429rem;
  line-height: 1.571429rem;
}

.title a {
  font-size: 1.142857rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hot_box {
  margin-top: 0.571429rem;
  width: 100%;
}

.dot {
  margin-left: 4px;
  margin-right: 0;
  width: 4px;
  height: 4px;
  background: #9e9e9e;
  border-radius: 2px;
  opacity: 0.5;
}

.dot:first-child {
  margin-left: 0;
}

.current {
  composes: dot;
  width: 12px;
  opacity: 1;
}