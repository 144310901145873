.stockBox {
  margin-top: 1.142857rem;
  height: 10.285714rem;
  width: 25.714286rem;
}

.stockCanvasBox {
  float: left;
  height: 10.285714rem;
  width: 16.428571rem;
  position: relative;
}

.stockCanvas {
  height: 10.285714rem;
  width: 16.428571rem;
}

.stockCanvasStartTime {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.857143rem;
  color: #999;
}

.stockCanvasEndTime {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.857143rem;
  color: #999;
}

.stockInfo {
  float: right;
  margin-top: 1.714286rem;
}

.stockInfoText {
  height: 1.571429rem;
  line-height: 1.571429rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #212223;
}

.stockInfoPrice {
  margin: 0.714286rem 0;
  height: 2.857143rem;
  line-height: 2.857143rem;
  font-size: 2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222;
}

.stockInfoRange {
  height: 1.428571rem;
  line-height: 1.428571rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222;
}

.stockInfoRangeItem:nth-child(1) {
  margin-right: 0.571429rem;
}

.stockRedColor {
  color: #f54343;
}

.stockGreenColor {
  color: #017f01;
}

.stockBlackColor {
  color: #222;
}