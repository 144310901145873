.title_img {
  display: block;
  width: 100%;
  height: 8.785714rem;
}

.title_img:hover {
  text-decoration: none;
}

.title_img:hover .title_img_info > span:first-child {
  color: #f54343;
}

.title_img .title_img_pic {
  float: left;
  margin: 1.428571rem 0.857143rem 0 0;
  width: 12.5rem;
  height: 7rem;
}

.title_img_info {
  float: left;
  display: block;
  margin-top: 1.428571rem;
  cursor: pointer;
}

.title_img_info span:first-child {
  display: block;
  height: 4.714286rem;
  line-height: 1.571429rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #212223;
  overflow: hidden;
  cursor: pointer;
}

.title_img_info {
  /* & span:last-child {
        display: block;
        margin-top: 15px;
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9e9e9e;
    } */
}

.long_info {
  width: 20.928571rem;
}

.short_info {
  width: 11.642857rem;
}

.mark {
  width: 1.714286rem;
  height: 1rem;
  margin-top: 1.285714rem;
}