.left_box {
  float: left;
  margin-right: 2.785714rem;
  width: 34.285714rem;
}

.dbox {
  margin-top: 1.428571rem;
  width: 34.285714rem;
  height: 13rem;
}

.adBox {
  height: 2.714286rem;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}