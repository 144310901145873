.content_list {
  position: absolute;
  top: 10.714286rem;
  left: 0;
  z-index: -1;
}

.chip_item {
  width: 14.285714rem;
  height: 1.785714rem;
}

.top_40 {
  margin-top: 2.857143rem;
}