.left_box {
  float: left;
  margin: 1.857143rem 2.857143rem 0 0;
  width: 34.285714rem;
}

.slide_box {
  width: 34.285714rem;
  height: 19.285714rem;
}

.slide_box [ifeng_ui_pc_sliders-dots] {
  position: absolute;
  left: 0;
  bottom: 0.571429rem;
  height: 0.285714rem;
  line-height: 0.285714rem;
  text-align: center;
}

.slide_box [ifeng_ui_pc_sliders-arrows_pre] {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1.785714rem;
  width: 1.714286rem;
  height: 3.571429rem;
  cursor: pointer;
  z-index: 10;
}

.slide_box [ifeng_ui_pc_sliders-arrows_next] {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -1.785714rem;
  width: 1.714286rem;
  height: 3.571429rem;
  cursor: pointer;
  z-index: 10;
}

.slide [ifeng_ui_pc_sliders-arrows_pre] {
  background: url('./pre.png') no-repeat center center;
}

.slide [ifeng_ui_pc_sliders-arrows_pre]:hover {
  background: url('./pre_hover.png') no-repeat center center;
}

.slide [ifeng_ui_pc_sliders-arrows_next] {
  background: url('./next.png') no-repeat center center;
}

.slide [ifeng_ui_pc_sliders-arrows_next]:hover {
  background: url('./next_hover.png') no-repeat center center;
}

.slide_2x [ifeng_ui_pc_sliders-arrows_pre] {
  background: url('./pre_2x.png') no-repeat;
  background-size: contain;
}

.slide_2x [ifeng_ui_pc_sliders-arrows_pre]:hover {
  background: url('./pre_hover_2x.png') no-repeat;
  background-size: contain;
}

.slide_2x [ifeng_ui_pc_sliders-arrows_next] {
  background: url('./next_2x.png') no-repeat;
  background-size: contain;
}

.slide_2x [ifeng_ui_pc_sliders-arrows_next]:hover {
  background: url('./next_hover_2x.png') no-repeat;
  background-size: contain;
}

.big_pic {
  position: relative;
  width: 34.285714rem;
  height: 19.285714rem;
}

.pic_img {
  display: block;
  width: 34.285714rem;
  height: 19.285714rem;
}

.pic_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 34.285714rem;
  height: 3.428571rem;
  background: #000000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .7)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);
  filter: alpha(opacity=70);
}

.title {
  position: absolute;
  left: 0.714286rem;
  bottom: 1.142857rem;
  width: 32.857143rem;
  height: 1.571429rem;
  line-height: 1.571429rem;
  overflow: hidden;
}

.title a {
  font-size: 1.142857rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.hot_box {
  margin-top: 0.571429rem;
  width: 100%;
}

.dot {
  margin-left: 4px;
  margin-right: 0;
  width: 4px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  opacity: 0.5;
}

.dot:first-child {
  margin-left: 0;
}

.current {
  composes: dot;
  width: 12px;
  opacity: 1;
}

.mark {
  width: 2rem;
  height: 1.214286rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.adBox {
  height: 2.714286rem;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}