.news_list {
  position: relative;
  width: 100%;
}

.news_list .news_list_p {
  width: 100%;
  height: 2.714286rem;
  line-height: 2.714286rem;
  overflow: hidden;
}

.news_list .news_list_p a {
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
}

.news_list .news_list_p a:hover {
  color: #f54343;
  text-decoration: none;
}

.news_list li {
  list-style: none;
}

.news_list li div {
  width: 100%;
  height: 2.714286rem;
  line-height: 2.714286rem;
  overflow: hidden;
}

.news_list li div a {
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
}

.news_list li div a:hover {
  color: #f54343;
  text-decoration: none;
}

.news_list_unfold .news_list_p {
  height: auto;
  line-height: 1.857143rem;
  padding: 0.428571rem 0;
  white-space: normal !important;
  text-overflow: clip !important;
  text-align: justify !important;
}

.news_list_unfold .news_list_p a {
  vertical-align: top;
}

.news_list_unfold .news_list_markTag {
  display: block;
  width: auto !important;
  position: relative !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
  padding: 0 30px 0 0 !important;
  height: 2.714286rem !important;
  line-height: 2.714286rem !important;
}

.zoujc {
  float: left;
  width: 4.5rem;
  height: 1.285714rem;
  margin-top: 0.714286rem;
  margin-right: 0.285714rem;
  background: url(./zoujc.png) no-repeat center center;
  background-size: contain;
}

.video {
  float: left;
  width: 1.428571rem;
  height: 1rem;
  margin-top: 0.857143rem;
  margin-right: 0.571429rem;
  background: url(./video.png) no-repeat center center;
}

.video_2x {
  float: left;
  width: 1.428571rem;
  height: 1rem;
  margin-top: 0.857143rem;
  margin-right: 0.571429rem;
  background: url(./video_2x.png) no-repeat;
  background-size: contain;
}

.videoYellow {
  float: left;
  width: 1.428571rem;
  height: 1rem;
  margin-top: 0.857143rem;
  margin-right: 0.571429rem;
  background: url(https://x0.ifengimg.com/ucms/2021_51/BA13E5E4B9ECFD1BDFABD92CF8B37D575EE78BC7_size2_w20_h14.jpg)
        no-repeat center center;
}

.video_2xYellow {
  float: left;
  width: 1.428571rem;
  height: 1rem;
  margin-top: 0.857143rem;
  margin-right: 0.571429rem;
  background: url(https://x0.ifengimg.com/ucms/2021_51/8199EBBDBBB838987C405B7699491AA1AD75F037_size1_w40_h28.png)
        no-repeat;
  background-size: contain;
}

.bold {
  font-family: PingFangSC-Semibold, PingFang SC !important;
  font-weight: 600 !important;
}

.cnRed {
  color: #f54343 !important;
}

.space {
  margin-left: 1.071429rem;
}

.space:first-child {
  margin-left: 0;
}

.news_list_markTag {
  display: block;
  width: auto !important;
  position: relative !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
  padding: 0 30px 0 0 !important;
  height: 2.714286rem !important;
  line-height: 2.714286rem !important;
}

.markTag {
  width: 24px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
}