.donate {
  width: 13.857143rem;
  height: 1.142857rem;
}

.donate a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.donate a img {
  width: 100%;
  height: 100%;
}