.top_img {
  width: 100rem;
  margin: 0 auto;
  position: relative;
}

.closed {
  position: absolute;
  top: 1.928571rem;
  right: 0;
  z-index: 10;
  width: 2.571429rem;
  height: 0.857143rem;
  cursor: pointer;
}

.a_link {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 4.5rem;
}