.logo {
  padding: 0.714286rem 0 0 1.071429rem;
  width: 9.285714rem;
  height: 3.285714rem;
}

.logo a {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.logo a img {
  width: 100%;
  height: 100%;
}