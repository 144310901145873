.yaowen_center_box {
  float: left;
  width: 34.285714rem;
  margin-right: 2.785714rem;
  padding-top: 1px;
}

:global(.hairlines) .yaowen_center_box {
  padding-top: 0.5px;
}

.yaowen_center_box h3,
.yaowen_center_box p {
  white-space: nowrap;
  text-overflow: ellipsis;
}