.navigation_box {
  position: relative;
  margin: 0 auto;
  width: 100rem;
  height: 2.857143rem;
  background: #f54343;
}

.navigation {
  composes: clearfix from global;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation li {
  float: left;
}

.navigation li a {
  display: block;
  width: 100%;
  height: 2.857143rem;
  line-height: 2.857143rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}

.navigation li a:hover {
  background: #f76969;
  text-decoration: none;
}