.sub_box {
  margin: 0 auto;
  width: 100rem;
  background: #ffffff;
}

.sub_nav {
  composes: clearfix from global;
}

.sub_nav li {
  float: left;
  padding: 0.642857rem 0;
}

.sub_nav li:first-child a {
  border-left: 0 !important;
}

.sub_nav li a {
  display: block;
  width: 100%;
  height: 1.428571rem;
  line-height: 1.428571rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
  text-align: center;
  border-left: 1px solid #e8e8e8;
}

:global(.hairlines) .sub_nav li a {
  border-left: 0.5px solid #e8e8e8;
}

.sub_nav li a:hover {
  color: #f54343;
  text-decoration: none;
}

.sub_nav li:first-child {
  background: #ffffff !important;
}

.sub_nav .fontRed {
  color: #f54343;
}