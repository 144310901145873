.header {
  composes: clearfix from global;
  position: relative;
  margin: 0 auto;
  width: 100rem;
  height: 4.714286rem;
  background: #ffffff;
}

.header_l {
  float: left;
}

.header_r {
  float: right;
}

.box_top {
  composes: clearfix from global;
  display: block;
  margin-top: 0.571429rem;
}

.jb_box {
  float: right;
  margin-right: 0.714286rem;
}

.donate_box {
  float: right;
  margin-top: 0.142857rem;
}

.login {
  float: right;
}

.login > div {
  float: left;
}

.search {
  margin-top: 0.642857rem;
}

.phtv {
  composes: clearfix from global;
  margin: 0.642857rem 0 0 0.714286rem;
}

.phtv:hover span {
  background: url(./phtv_hover.png) no-repeat left center;
}

.phtv:hover a {
  color: #f54343;
  text-decoration: none;
}

.phtv span {
  float: left;
  width: 1.714286rem;
  height: 1.714286rem;
  background: url(./phtv.png) no-repeat left center;
}

.phtv a {
  float: left;
  display: block;
  height: 1.714286rem;
  line-height: 1.714286rem;
  font-size: 0.857143rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212223;
}

.phtv_2x {
  composes: clearfix from global;
  margin: 0.642857rem 0 0 0.714286rem;
}

.phtv_2x:hover span {
  background: url(./phtv_hover_2x.png) no-repeat;
  background-size: contain;
}

.phtv_2x:hover a {
  color: #f54343;
  text-decoration: none;
}

.phtv_2x span {
  float: left;
  width: 1.714286rem;
  height: 1.714286rem;
  background: url(./phtv_2x.png) no-repeat;
  background-size: contain;
}

.phtv_2x a {
  float: left;
  display: block;
  height: 1.714286rem;
  line-height: 1.714286rem;
  font-size: 0.857143rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212223;
}

.ifeng {
  composes: clearfix from global;
  margin: 0.642857rem 0 0 0.714286rem;
}

.ifeng:hover span {
  background: url(./feng_hover.png) no-repeat left center;
}

.ifeng:hover a {
  color: #f54343;
  text-decoration: none;
}

.ifeng span {
  float: left;
  width: 1.714286rem;
  height: 1.714286rem;
  background: url(./feng.png) no-repeat left center;
}

.ifeng a {
  float: left;
  display: block;
  height: 1.714286rem;
  line-height: 1.714286rem;
  font-size: 0.857143rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212223;
}

.ifeng_2x {
  composes: clearfix from global;
  margin: 0.642857rem 0 0 0.714286rem;
}

.ifeng_2x:hover span {
  background: url(./feng_hover_2x.png) no-repeat;
  background-size: contain;
}

.ifeng_2x:hover a {
  color: #f54343;
  text-decoration: none;
}

.ifeng_2x span {
  float: left;
  width: 1.714286rem;
  height: 1.714286rem;
  background: url(./feng_2x.png) no-repeat;
  background-size: contain;
}

.ifeng_2x a {
  float: left;
  display: block;
  height: 1.714286rem;
  line-height: 1.714286rem;
  background-size: contain;
  font-size: 0.857143rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212223;
}

.user {
  margin: 0.642857rem 0 0 0.714286rem;
}