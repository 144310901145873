.title_box {
  position: relative;
  height: 4.285714rem;
  border-bottom: 1px solid #f3e5cc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:global(.hairlines) .title_box {
  border-bottom: 0.5px solid #f3e5cc;
}

.longer_title_box {
  width: 71.428571rem;
}

.long_title_box {
  width: 62.857143rem;
}

.middle_title_box {
  width: 34.285714rem;
}

.short_title_box {
  width: 25.714286rem;
}

.title {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2.642857rem;
  line-height: 2rem;
  z-index: 9;
}

.title span {
  float: left;
  padding-bottom: 0.428571rem;
  height: 2rem;
  line-height: 2rem;
  border-bottom: 0.285714rem solid #d6ab56;
  font-size: 1.428571rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212223;
}

.title a {
  float: left;
  padding-bottom: 0.428571rem;
  height: 2rem;
  line-height: 2rem;
  border-bottom: 0.285714rem solid #d6ab56;
  font-size: 1.428571rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212223;
}

.title a:hover {
  color: #f54343;
  text-decoration: none;
}

.phtvIcon {
  position: absolute;
  right: -0.571429rem;
  top: -0.428571rem;
  width: 0.785714rem;
}

.dr {
  margin: 0.357143rem 1.428571rem 0;
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
  height: 1.285714rem;
  border-right: 1px solid #e8e8e8;
}

:global(.hairlines) .dr {
  border-right: 0.5px solid #e8e8e8;
}

.no_active {
  color: #757575 !important;
}

.tab {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}

.more {
  position: absolute;
  right: 0;
}