.footer {
  margin: 2.142857rem auto 1.142857rem;
  width: 100rem;
  text-align: center;
}

.footer > div[city],
.footer > div[intro] {
  color: #666;
  padding-bottom: 0.928571rem;
  border-bottom: 0.142857rem solid #f54343;
}

.footer > div[city] div,
.footer > div[intro] div {
  float: left;
}

.footer > div[city] p,
.footer > div[intro] p {
  float: right;
}

.footer > div[city] p span,
.footer > div[city] p em,
.footer > div[intro] p span,
.footer > div[intro] p em {
  float: left;
  margin: 0.142857rem 0 0 0;
  padding: 0 0.571429rem 0.142857rem;
  font-size: 1.142857rem;
}

.footer > div[city] a,
.footer > div[intro] a {
  float: left;
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
}

.footer > div[city] a:hover,
.footer > div[intro] a:hover {
  color: #f54343;
  text-decoration: none;
}

.footer > div[intro] {
  padding-top: 0.857143rem;
  padding-bottom: 0;
  border-bottom: none;
}

.footer > div[copr] {
  margin-top: 2.142857rem;
}

.footer > div[copr] div {
  margin-bottom: 1.142857rem;
}

.footer > div[copr] div span {
  padding: 0 0.571429rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #757575;
}

.footer > div[copr] div span a {
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #757575;
}

.footer > div[copr] div span a:hover {
  color: #f54343;
  text-decoration: none;
}

.footer > div[org] {
  margin-top: 1rem;
}

.footer > div[org] a {
  float: left;
  display: block;
  width: 11rem;
  height: 5rem;
  border: 1px solid #d2d2d2;
  text-align: left;
  line-height: 1.428571rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212223;
}

:global(.hairlines) .footer > div[org] a {
  border: 0.5px solid #d2d2d2;
}

.footer > div[org] a:hover {
  color: #f54343;
  text-decoration: none;
}

.footer > div[org] a span {
  cursor: pointer;
}

.footer > div[org] a[org01] {
  margin-right: 6.142857rem;
}

.footer > div[org] a[org01] img {
  float: left;
  margin: 0.785714rem 0 0 0.571429rem;
  width: 3.428571rem;
  height: 3.428571rem;
}

.footer > div[org] a[org01] > span {
  float: left;
  margin: 1.142857rem 0 0 0.714286rem;
}

.footer > div[org] a[org02] {
  margin-right: 6.142857rem;
  width: 12.857143rem;
  text-align: center;
}

.footer > div[org] a[org02] img {
  float: left;
  margin: 0.785714rem 0 0 0.571429rem;
  width: 3.428571rem;
  height: 3.428571rem;
}

.footer > div[org] a[org02] > span {
  float: left;
  margin: 1.142857rem 0 0 0.714286rem;
}

.footer > div[org] a[org03] {
  margin-right: 6.142857rem;
}

.footer > div[org] a[org03] img {
  margin-top: 0.5rem;
  width: 11rem;
  height: 4rem;
}

.footer > div[org] a[org04] {
  margin-right: 6.142857rem;
  width: 10.357143rem;
}

.footer > div[org] a[org04] img {
  float: left;
  margin: 0.785714rem 0 0 0.571429rem;
  width: 3.428571rem;
  height: 3.428571rem;
}

.footer > div[org] a[org04] > span {
  float: left;
  margin: 1.142857rem 0 0 0.714286rem;
}

.footer > div[org] a[org05] {
  margin-right: 6.142857rem;
}

.footer > div[org] a[org05] img {
  float: left;
  margin: 0.785714rem 0 0 0.571429rem;
  width: 3.428571rem;
  height: 3.428571rem;
}

.footer > div[org] a[org05] > span {
  float: left;
  margin: 1.142857rem 0 0 0.714286rem;
}

.footer > div[org] a[org06] {
  margin-right: 6.142857rem;
  width: 11.357143rem;
}

.footer > div[org] a[org06] img {
  float: left;
  margin: 0.785714rem 0 0 0.571429rem;
  width: 3.428571rem;
  height: 3.428571rem;
}

.footer > div[org] a[org06] > span {
  float: left;
  margin: 0.571429rem 0 0 0.714286rem;
}

.footer > div[org] a[org07] {
  width: 12rem;
}

.footer > div[org] a[org07] img {
  float: left;
  margin: 0.785714rem 0 0 0.571429rem;
  width: 3.428571rem;
  height: 3.428571rem;
}

.footer > div[org] a[org07] > span {
  float: left;
  margin: 1.142857rem 0 0 0.714286rem;
}