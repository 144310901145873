.qr_box {
  position: fixed;
  display: block;
  top: 37.142857rem;
  right: 0;
  width: 8.142857rem;
  height: 11.428571rem;
  z-index: 100;
}

.qr_box img {
  float: left;
  display: block;
}

.qr {
  display: block;
  width: 8.142857rem;
  height: 11.428571rem;
  background: #ebebeb;
}

.qr:hover {
  text-decoration: none;
}

.title {
  padding: 0.285714rem 0;
  height: 1.214286rem;
  line-height: 1.214286rem;
  font-size: 0.857143rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  text-align: center;
}

.img {
  margin: 0 auto;
  /* width: 94px;
    height: 94px; */
  width: 8.142857rem;
  height: 11.428571rem;
}

.img img {
  width: 100%;
  height: 100%;
}

.tip {
  padding: 0.428571rem 0;
}

.tip p {
  height: 1rem;
  line-height: 1rem;
  font-size: 0.857143rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  text-align: center;
}

.close {
  position: absolute;
  top: -1.357143rem;
  right: 0;
  font-size: 0.857143rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}