.promote_box {
  margin-top: 0.857143rem;
  width: 100%;
  height: 3.857143rem;
}

.promote_box a {
  width: 100%;
  height: 100%;
}

.promote_box a img {
  width: 100%;
  height: 100%;
}