.subTitle {
  padding: 0.571429rem 0 0.571429rem 0.857143rem;
  font-size: 1.142857rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #212223;
  line-height: 1.571429rem;
  position: relative;
}

.subTitleLine {
  position: absolute;
  left: 0;
  top: 0.714286rem;
  width: 0.285714rem;
}

.subTitleLink {
  color: #212223 !important;
}

.subTitleLink:hover {
  color: #f54343 !important;
  text-decoration: none;
}