.right_box {
  float: left;
  width: 25.714286rem;
}

.stock_box {
  margin-top: 1.142857rem;
  width: 100%;
}

.adBox {
  height: 2.714286rem;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}